.App {
  text-align: center;
}

.start_container {
    background-color: black;

    @media screen and (min-width: 800px) {
        padding: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: absolute;
    }
}

.startup {
    @media screen and (min-width: 800px) {
        width: 100vw;
        position: absolute;
    }

    @media screen and (max-width: 800px) {
        height: 100vh;
        width: 100%;
        text-align: center;
    }
}

.page-top {
    display: none;
    position: fixed;
    right: 70px;
    bottom: 70px;
    z-index: 7000;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    @media screen and (max-width: 800px) {
        right: 30px;
        bottom: 30px;
    }

    :before {
        background: url('./Assets/Icon/top.svg') 50% 50% no-repeat;
        background-size: 100% 100%;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 18px;
        height: 20px;
        margin: auto;
    }

    a {
        background: #333;
        display: block;
        position: relative;
        width: 50px;
        height: 50px;
        font: 0 / 0 a;
        border-radius: 50%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
}


@media screen and (min-width: 800px) {
    @media screen and (max-aspect-ratio: 3/2) {
        .startup {
            width: auto;
            height: 100vh;
        }
    }
}

.ReactModal__Content::-webkit-scrollbar {
    display:none;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index:999;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}