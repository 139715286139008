.intro {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #ffffff;
    padding: 0;
    margin: 0;
    border: 0;

    .loading-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
    }

    .logo-container {
        position: relative;
        width: 30vw;
        height: 20vw;
    }

    .logo {
        width: 30vw;
        height: 20vw;
        opacity: 0;
        transform: scale(0);
        animation: popIn 1s ease forwards;
    }

    .shine {
        position: absolute;
        top: 0;
        left: -100%;
        width: 200%;
        height: 100%;
        background-image: linear-gradient(120deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
        animation: sparkle 2s linear infinite;
        pointer-events: none;
    }

    @keyframes popIn {
        0% {
            transform: scale(0);
            opacity: 0;
        }

        60% {
            transform: scale(1.2);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes sparkle {
        0% {
            left: -100%;
        }

        100% {
            left: 100%;
        }
    }
}
